import styled from 'styled-components';
import media from 'styled-media-query';
import Img from 'gatsby-image';


export const Item = styled.div`
`;

export const ItemImgWrapper = styled.div`
  display:flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%);
`;

export const ItemImg = styled.img`
width: 44px;
`;

export const ItemTitle = styled.h3`
  margin-top: var(--space-sm);
  font-weight: 600;
`;

export const ItemText = styled.p`
  margin-top: var(--space-sm);
  color: var(--text-color);  
`;
