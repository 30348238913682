import React from 'react';
import useTranslations from '../useTranslations';
import { useStaticQuery, graphql } from 'gatsby';
import * as S from './styled';
import useResponsiveImage from '../useResponsiveImage';
import TryNowButton from '../TryNowButton';

const Banner = ({ bannerContent, lang }) => {
    const {
        buttonAppointmentUrl,
        buttonAppointment,
    } = useTranslations(lang)

    const { icon } = useStaticQuery(
        graphql`
            query {
                icon: allFile(filter: { name: { eq: "icon-checkmark" } }) {
                    edges {
                        node {
                            publicURL
                        }
                    }
                }
            }
        `
    )

    const iconUrl = icon.edges[0].node.publicURL

    return (
        <S.BannerWrapper>
            <S.Container>
                <S.ButtonTextContainer>
                    <S.Title dangerouslySetInnerHTML={{ __html: bannerContent.text}}></S.Title>

                    <S.Bullets>
                        <li style={{ backgroundImage: `url(${iconUrl})` }}>
                            {bannerContent.bullet1}
                        </li>
                        <li style={{ backgroundImage: `url(${iconUrl})` }}>
                            {bannerContent.bullet2}
                        </li>
                        <li style={{ backgroundImage: `url(${iconUrl})` }}>
                            {bannerContent.bullet3}
                        </li>
                    </S.Bullets>

                    <div>
                        {bannerContent.buttonText &&
                        bannerContent.buttonUrlInternal ? (
                            <S.NavigationButtonInternal to={bannerContent.buttonUrlInternal} lang={lang} >
                                {bannerContent.buttonText}
                            </S.NavigationButtonInternal>
                        ) : (
                            <>
                                <TryNowButton lang={lang} large={true} />

                                <S.NavigationButtonSecondary
                                    href={buttonAppointmentUrl}
                                    rel="noopener noreferrer"
                                >
                                    {buttonAppointment}
                                </S.NavigationButtonSecondary>
                            </>
                        )}
                    </div>
                </S.ButtonTextContainer>
            </S.Container>

            <S.BackgroundImageWrapper>
                <S.BackgroundImage
                    fluid={useResponsiveImage(bannerContent.image, false)}
                    alt={bannerContent.text}
                />
            </S.BackgroundImageWrapper>
        </S.BannerWrapper>
    )
}

export default Banner
